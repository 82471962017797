
@font-face {
    font-family: 'Freight';
    src: local('Freight'), url('/fonts/PSB/Freight Text Book Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Minion Pro';
    src: local('Minion Pro'), url('/fonts/PSB/MinionPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}





